// libraries
import { ReactElement, ReactNode, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useToggle, useUpdateEffect } from 'react-use'

// constants
import {
  BUTTON_ICON_POSITIONS,
  BUTTON_VARIANTS,
} from 'components/common/Button'
import { BADGE_TYPES, DEFAULT_LIST_FILTERS } from 'constants/common'

// components
import {
  Badge,
  Button,
  ListToolbox,
  ListFilterBuilder,
  PageHeader,
  SplitButton,
} from 'components/common'

import type { DropdownOption } from 'components/common/Dropdown'
import type { ListConditions } from 'types/common'
import type { Filters } from 'types/filter'
import type { OnGroupByChange } from 'types/gallery'
import type { ColumnOptions } from 'components/common/DataTable/useDataTableColumns'
import type { PageHeaderProps } from 'components/common/PageHeader'
import type { ToolboxOptions } from 'components/common/List/Toolbox'

// utils
import { useStaticResourceStateValue } from 'contexts'
import { useGalleryOptionsStorage } from 'hooks'
import usePageFiltersBuilder, {
  UsePageFiltersBuilderProps,
} from './usePageFiltersBuilder'

import scss from './index.module.scss'

export type GalleryPageHeaderProps = Partial<
  {
    iconName?: string
    toolboxOptions: ToolboxOptions
    onConditionsChange: React.Dispatch<React.SetStateAction<ListConditions>>
    fetchList?: (enableLoadMore?: boolean) => Promise<unknown[]>
    onExport?: () => void
    filterValues?: Filters
    setFilterValues?: React.Dispatch<Filters>
    pageName?: string | null
    counts: number
    isLoading: boolean
    actions?: DropdownOption<string>[]
    bulkActions?: DropdownOption<string>[]
    columns: ColumnOptions[]
    visibleColumns: ColumnOptions[]
    setVisibleColumns: (columns: ColumnOptions[]) => void
    entity: string
    filtersEntity?: string
    conditions: ListConditions
    onGroupByChange?: OnGroupByChange
    renderAdditionalTools?: () => ReactNode
    renderLeftAdditionalTool?: () => ReactNode
    showHeaderBar?: boolean
  } & UsePageFiltersBuilderProps &
    Partial<PageHeaderProps>
>

const GalleryPageHeader = ({
  iconName,
  entity,
  filtersEntity,
  pageName,
  conditions,
  onConditionsChange,
  toolboxOptions,
  fetchList,
  onExport,
  filterValues,
  setFilterValues,
  customizedFiltersKeys = DEFAULT_LIST_FILTERS,
  customFiltersSpecs,
  counts,
  isLoading = true,
  actions = [],
  bulkActions,
  columns,
  visibleColumns,
  setVisibleColumns,
  filterSpecsOverride,
  onGroupByChange,
  renderAdditionalTools,
  renderLeftAdditionalTool,
  showHeaderBar = true,
  ...rest
}: GalleryPageHeaderProps): ReactElement => {
  const { enableFilter = true, show = true } = toolboxOptions || {}

  const { filtersSpecs, onFiltersChange } = usePageFiltersBuilder({
    entity: filtersEntity ?? entity,
    customizedFiltersKeys,
    customFiltersSpecs,
    setFilterValues,
    filterSpecsOverride,
  })

  useGalleryOptionsStorage({
    enable: show,
    entity,
    listConditions: conditions,
    visibleColumns,
  })

  const renderBulkActions = () => (
    <>
      {_.map(bulkActions, action => {
        const { label, onClick, value } = action
        return (
          <Button
            key={value}
            variant={BUTTON_VARIANTS.secondary}
            onClick={onClick}
            testId={value}
          >
            {label}
          </Button>
        )
      })}
    </>
  )

  const renderActions = () => {
    const {
      icon,
      onClick,
      iconSize,
      value: testId,
      label: content,
      iconPosition = BUTTON_ICON_POSITIONS.left,
    } = _.first(actions) ?? {}

    const commonProps = {
      onClick,
      testId,
    }

    return actions.length > 1 ? (
      <SplitButton {...commonProps} options={actions.slice(1)}>
        {content}
      </SplitButton>
    ) : (
      <Button {...commonProps} {...(icon && { icon, iconSize, iconPosition })}>
        {content}
      </Button>
    )
  }

  const filtersSpecsKeys = useMemo(
    () => _.map(filtersSpecs, 'key'),
    [filtersSpecs]
  )

  const filtersSpecsValues = useMemo(
    () => _.pick(filterValues, filtersSpecsKeys),
    [filterValues, filtersSpecsKeys]
  )

  const filterSpecsValueLength = useMemo(
    () => _.size(filtersSpecsValues),
    [filtersSpecsValues]
  )

  const [isShowingFilters, toggleShowFilters] = useToggle(false)

  useUpdateEffect(() => {
    /*
  - Only display filters when there are saved filters (initial load)
  - Once the page loaded, the user decides to display filters or not by Filter toggle
  */
    if (_.isNil(isShowingFilters)) {
      toggleShowFilters(filterSpecsValueLength > 0)
    }
  }, [filterSpecsValueLength])

  const shouldRenderToolbox =
    entity &&
    (_.isFunction(onConditionsChange) || _.isFunction(onFiltersChange))

  const shouldRenderToolbarAndFilter =
    shouldRenderToolbox || _.isFunction(setFilterValues)

  const shouldRenderFilter =
    enableFilter &&
    shouldRenderToolbarAndFilter &&
    !_.isEmpty(filtersSpecs) &&
    isShowingFilters

  const renderToolbar = () =>
    shouldRenderToolbox && (
      <div className='d-flex justify-content-between align-items-center'>
        <ListToolbox
          entity={entity}
          conditions={conditions}
          onChange={onConditionsChange}
          filtersSpecs={filtersSpecs}
          options={{
            ...toolboxOptions,
            hideFiltersButton: _.isEmpty(filtersSpecs),
          }}
          filterValues={filterValues}
          onRefresh={fetchList}
          onExport={onExport}
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          onGroupByChange={onGroupByChange}
          onFiltersChange={onFiltersChange}
          renderAdditionalTools={renderAdditionalTools}
          {...(_.isFunction(setFilterValues) &&
            enableFilter && {
              filtersCount: filterSpecsValueLength,
              toggleShowFilters,
              isShowingFilters,
            })}
        />
      </div>
    )

  const onFilterBuilderChange = useCallback(
    (newFiltersSpecsValues: Filters) => {
      onFiltersChange({
        ..._.omit(filterValues, filtersSpecsKeys),
        ...newFiltersSpecsValues,
      })
    },
    [filterValues, filtersSpecsKeys, onFiltersChange]
  )

  const renderFilters = () =>
    shouldRenderFilter && (
      <ListFilterBuilder
        data-testid='ListFilterBuilder'
        filters={filtersSpecsValues}
        filtersSpecs={filtersSpecs}
        onChange={onFilterBuilderChange}
        className={scss.filters}
      />
    )

  const shouldRenderActions =
    (bulkActions && !_.isEmpty(bulkActions)) || (actions && !_.isEmpty(actions))

  const shouldRenderToolbar =
    show &&
    (shouldRenderToolbarAndFilter || shouldRenderFilter || shouldRenderActions)

  const { Icons } = useStaticResourceStateValue()
  const Icon = _.get(Icons, iconName as string)

  return (
    <PageHeader
      {...(showHeaderBar && { titleComponent: pageName ?? `${entity}s` })}
      iconName={Icon ? <Icon size={14} width={14} /> : undefined}
      {...rest}
      titleAddonComponent={
        <>
          {!_.isNil(counts) && !isLoading && (
            <Badge
              content={counts}
              type={BADGE_TYPES.infoGrey}
              className='ms-2'
            />
          )}
        </>
      }
      {...(shouldRenderToolbar && {
        toolbarComponent: (
          <div>
            <div className='d-flex justify-content-between'>
              {bulkActions && !_.isEmpty(bulkActions) ? (
                renderBulkActions()
              ) : actions && !_.isEmpty(actions) ? (
                renderActions()
              ) : renderLeftAdditionalTool ? (
                renderLeftAdditionalTool()
              ) : (
                <div />
              )}
              {renderToolbar()}
            </div>
            {renderFilters()}
          </div>
        ),
      })}
    />
  )
}

export default GalleryPageHeader
